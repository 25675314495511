/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-02",
    versionChannel: "nightly",
    buildDate: "2023-11-02T00:22:01.505Z",
    commitHash: "3572367c8e99fd4361c349cd79e65a6d7c31213c",
};
